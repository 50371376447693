
// Site variables
export let site = {
    name : 'Carnaval24',
    motto : {
        ro : 'Carnaval',
        en : 'Carnaval'
    },
    pages : {
        home : {
            title : {
                ro : 'Costume Tematice, Decoratiuni si Accesorii',
                en : 'Themed Costumes, Decorations, and Accessories'
            },
            description : {
                ro : 'Costume si accesorii pentru petreceri, serbari si diverse evenimente, atent selectate pentru a oferi cea mai buna calitate la un pret avantajos.',
                en : 'Costumes and accessories for parties, celebrations, and various events, carefully selected to offer the best quality at an advantageous price'
            }
        }
    },
    about : {
        ro : 'Carnaval24 a fost lansat în 2015 si oferă produse de carnaval pentru copii și adulți. Descoperă costumații și accesorii tematice de cea mai înaltă calitate, transformând evenimentele într-un adevărat carnaval!',
        en : 'Carnaval24 was launched in 2015 and offers carnival products for children and adults. Discover the highest quality themed costumes and accessories, turning events into a real carnival!'
    },
    showNewsletterPopup: false,
    showNewsletterDiscountText: true,
}

// Site languages
export let languages = [
    {
        iso_693_1_code: 'ro',
        iso_name: 'Romana',
        flag : '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ro" viewBox="0 0 512 512"><g fill-rule="evenodd" stroke-width="1pt"><path fill="#00319c" d="M0 0h170.7v512H0z"/><path fill="#ffde00" d="M170.7 0h170.6v512H170.7z"/><path fill="#de2110" d="M341.3 0H512v512H341.3z"/></g></svg>'
   },
    // {
    //     iso_693_1_code: 'en',
    //     iso_name: 'English',
    //     flag : '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 512 512"><path fill="#012169" d="M0 0h512v512H0z"/><path fill="#FFF" d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"/><path fill="#C8102E" d="m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"/><path fill="#FFF" d="M176 0v512h160V0H176zM0 176v160h512V176H0z"/><path fill="#C8102E" d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z"/></svg>'
    // },
];

// 2Performant
export let tooperformant = {
    enabled : true,
    campaign_unique : 'd401268fb',
    confirm : '5c533ff716e21eae'
}

// Store configuration
export let store = {

    product_max_quantity : 10000,
    limitMaxQuantity(value){
        return (value > this.product_max_quantity) ? this.product_max_quantity : value
    },
    gift_wrap_uuid : '2933ec47-b4eb-315c-8b2f-85d4348f',
    free_shipping_threshold : 250,
    product_tile_button_template : 'button',
    add_to_cart_confirmation_popup : true,
    add_to_cart_quantity_controls : false,
    clear_cart_button : false,
    checkout_type : 'three_step_checkout',
    checkout_hide_shipping: false,
    checkout_show_vat : false,
    product_page : {
        product_details_tabs : [
            'description','specs','faq'
        ],
        show_discreet_shipping: false,
        show_shipping_cost : true,
        show_phone_numbers : true,
		allow_price_zero_purchases: true
    }
}

export let footer_navigation_1_data = {
    'ro' : [
        {
            title : 'Detalii livrare',
            route : {
                name : null,
                slug : 'detalii-livrare'
            },
            children : []
        },
        {
            title : 'Retur si Garantie',
            route : {
                name : null,
                slug : 'retur-si-garantie'
            },
            children : []
        },
        {
            title : 'Rezolvarea alternativa a litigiilor',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
        {
            title : 'Rezolvarea online a litigiilor',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
    ],
    'en' : [
        {
            title : 'Delivery details',
            route : {
                name : null,
                slug : 'delivery-details'
            },
            children : []
        },
        {
            title : 'Return and Warranty',
            route : {
                name : null,
                slug : 'return-and-warranty'
            },
            children : []
        },
        {
            title : 'Alternative dispute resolution',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
        {
            title : 'Online dispute resolution',
            route : {
                name : null,
                slug : null,
                url : 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
            },
            children : []
        },
    ]
}


export let footer_navigation_2_data = {
    'ro' : [
        {
            title : 'FAQ',
            route : {
                name : null,
                slug : 'faq'
            },
            children : []
        },
        {
            title : 'Contactati-ne',
            route : {
                name : null,
                slug : 'contact'
            },
            children : []
        },
        {
            title : 'Termeni si conditii',
            route : {
                name : null,
                slug : 'termeni-si-conditii'
            },
            children : []
        },
        {
            title : 'Date cu caracter personal',
            route : {
                name : null,
                slug : 'date-cu-caracter-personal'
            },
            children : []
        }
    ],
    'en' : [
        {
            title : 'FAQ',
            route : {
                name : null,
                slug : 'faq'
            },
            children : []
        },
        {
            title : 'Contact us',
            route : {
                name : null,
                slug : 'contact-us'
            },
            children : []
        },
        {
            title : 'Terms and conditions',
            route : {
                name : null,
                slug : 'terms-and-conditions'
            },
            children : []
        },
        {
            title : 'Personal data',
            route : {
                name : null,
                slug : 'personal-data'
            },
            children : []
        }
    ]
}


export let contact_information = {
    email : 'comenzi@carnaval24.ro',
    phones : [
        {
            'label' : '0376.448.040',
            'value' : '0376448040'
        },
        {
            'label' : '0730.556.787',
            'value' : '0730556787'
        }
    ],
    whatsapp_phone_number: '+40730556787',
    show_whatsapp_button: true,
    program : {
        ro : 'Zilnic 8:30 - 20:30',
        en : 'Daily 8:30 - 20:30'
    }
}